import { Box } from '@mui/system';

import { USER_PERMISSIONS } from '../../../../constants/permissions/userPermissions';
import { IUserPermissions, useUserPermissionType } from '../../../../hooks/useUserPermissionType';
import { IVerticalLayoutProp, VerticalLayout } from '../../../common/pageLayouts/VerticalLayout';
import CampaignInstanceDynamicRibbon from './overview/CampaignInstanceDynamicRibbon';
import CampaignInstanceGeneral from './overview/CampaignInstanceGeneral';
import CampaignInstanceInternal from './overview/CampaignInstanceInternal';
import CampaignInstanceLegal from './overview/CampaignInstanceLegal';
import CampaignInstanceMarketing from './overview/CampaignInstanceMarketing';

const permissionsObj: IUserPermissions = {
  brandPermission: USER_PERMISSIONS.BRAND_CAMPAIGN_INSTANCE_INVITATION_READ,
};
const CampaignInstanceOverview = () => {
  const { brandPermission } = useUserPermissionType(permissionsObj);
  const componentsToRender: IVerticalLayoutProp[] = [
    {
      component: <CampaignInstanceGeneral />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_GENERAL_READ,
    },
    {
      component: <CampaignInstanceInternal />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_INTERNAL_READ,
    },
    {
      component: <CampaignInstanceMarketing />,
      requiredPermission: USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_MARKETING_READ,
    },
    {
      component: <CampaignInstanceLegal />,
      requiredPermission: [
        USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_LEGAL_READ,
        USER_PERMISSIONS.BRAND_CAMPAIGN_INSTANCE_OVERVIEW_LEGAL_TERMS_READ,
      ],
    },
  ];

  return (
    <Box>
      {brandPermission && <CampaignInstanceDynamicRibbon />}
      <VerticalLayout components={componentsToRender} />
    </Box>
  );
};

export default CampaignInstanceOverview;
