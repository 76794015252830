import { DynamicRibbonTaskInstanceAssets } from '#/components/campaigns/campaignAssets/DynamicRibbonTaskInstanceAssets';

import PermissionWrapper from '#/utils/PermissionWrapper';

import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { usePageContainerStyles } from '../../features/campaigns/common.style';
import { CreativeSpecs } from './CreativeSpecs';
import TacticsAssets from './TacticsAssets';

export const CampaignAssets = () => {
  const classes = usePageContainerStyles();

  return (
    <>
      <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.BRAND_CAMPAIGN_INSTANCES_ASSETS_TACTICS]}>
        <DynamicRibbonTaskInstanceAssets />
      </PermissionWrapper>
      <div className={classes.pageContainer}>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_INSTANCE_OVERVIEW_CREATIVE_SPECS_READ]}>
          <CreativeSpecs />
        </PermissionWrapper>
        <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_INSTANCE_ASSETS]}>
          <TacticsAssets />
        </PermissionWrapper>
      </div>
    </>
  );
};
