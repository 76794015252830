import * as E from 'fp-ts/lib/Either';
import * as J from 'fp-ts/lib/Json';
import * as t from 'io-ts';
import { identity, pipe } from 'fp-ts/lib/function';

import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import log from '#/utils/log';

const setTypedItem = <C extends t.Mixed>(key: string, codec: C, value: t.TypeOf<C>): void => {
  localStorage.setItem(key, JSON.stringify(codec.encode(value)));
};

const getTypedItem = <C extends t.Mixed>(key: string, codec: C, failedValue?: unknown): t.TypeOf<C> =>
  pipe(
    localStorage.getItem(key),
    t.union([t.null, t.undefined, t.string]).decode,
    E.chain(
      (s): E.Either<unknown, t.TypeOf<C>> =>
        s
          ? pipe(
              J.parse(s),
              E.chain((o) =>
                pipe(
                  o,
                  codec.decode,
                  E.mapLeft((e): unknown => e),
                ),
              ),
              E.mapLeft((e): unknown => e),
            )
          : E.right<unknown, t.TypeOf<C>>(failedValue),
    ),
    E.fold((e) => {
      log.error(`Failed to decode LocalStorage data at ${key}: ${e}`);
      return failedValue;
    }, identity),
  );

export const storageKeys = {
  REDIRECT_URL: 'redirectUrl',
};

export const types = {
  redirectUrl: t.type({ pathname: t.string, search: t.union([t.string, t.undefined]) }),
};

export type AppStorage = {
  setRedirectUrl: (pathname: string, search?: string) => void;
  getRedirectUrl: () => { pathname: string; search?: string };
  deleteRedirectUrl: () => void;
  clear: () => void;
};

export const storage: AppStorage = {
  setRedirectUrl: (pathname: string, search?: string) =>
    setTypedItem(storageKeys.REDIRECT_URL, types.redirectUrl, {
      pathname,
      search,
    }),
  getRedirectUrl: () =>
    getTypedItem(storageKeys.REDIRECT_URL, types.redirectUrl, {
      pathname: ROUTE_PATHS.ROOT,
      search: undefined,
    }),
  deleteRedirectUrl: () => localStorage.removeItem(storageKeys.REDIRECT_URL),
  clear: () => localStorage.clear(),
};
