import { FC } from 'react';

import { useAppSelector } from '#/hooks';

import EditIcon from '../../../assets/Edit.svg';
import { THEME } from '../../../constants/theme/constants';
import NewButton from '../../common/button/NewButton';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { style, useTopSectionStyles } from './TopSection.style';

interface IDeprecatedTopSectionProps {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  sectionName: string;
  requiredPermission?: string;
}

export const DeprecatedTopSection: FC<IDeprecatedTopSectionProps> = ({
  setEditMode,
  editMode,
  requiredPermission,
  sectionName,
}) => {
  const { permissions } = useAppSelector((state) => state.user);
  const classes = useTopSectionStyles();

  const hasWritePermissions = requiredPermission ? permissions.includes(requiredPermission) : true;

  return (
    <div className={classes.deprecatedContainer}>
      <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Dark} sx={style.sectionName} variant="H4_Base">
        {sectionName}
      </TypographyWithFontFamily>
      {hasWritePermissions && (
        <div className={classes.buttonsSection}>
          {!editMode ? (
            <NewButton
              icon={EditIcon}
              iconPosition="left"
              onClick={() => {
                setEditMode(true);
              }}
              text="Edit"
              variant="Ghost"
            />
          ) : (
            <div className={classes.deprecatedContainer}>
              <NewButton onClick={() => setEditMode(false)} text="Cancel" variant="Secondary" />
              <NewButton sx={style.saveButton} text="Save" type="submit" variant="Primary" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
