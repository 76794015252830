import { Box, CssBaseline } from '@mui/material';

import { SnackbarWrapper } from './components/common/snackbar/SmackbarWrapper';
import { SecurityRoutes } from './constants/routing/SecurityRoutes';

function App() {
  return (
    <Box sx={{ background: '#F1F0F7', minHeight: '100vh' }}>
      <CssBaseline /> {/*removes browser default CSS */}
      <SnackbarWrapper>
        <SecurityRoutes />
      </SnackbarWrapper>
    </Box>
  );
}

export default App;
