import { OktaAuth } from '@okta/okta-auth-js';

import { OKTA_CLIENT_ID, OKTA_ISSUER } from '#/constants/general';

export const oktaConfig = new OktaAuth({
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: '/login/callback',
  scopes: ['openid'],
  pkce: false,
});
