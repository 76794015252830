import { useEffect } from 'react';

import DynamicRibbon from '#/components/features/dynamicRibbon/DynamicRibbon';

import useGetResource from '#/hooks/useGetResource';

import { useGetRibbonTasksInstanceAssetsMutation } from '#/store/api/ribbon/ribbon';

export const DynamicRibbonTaskInstanceAssets = () => {
  const resource = useGetResource();

  const [trigger, { data, isLoading }] = useGetRibbonTasksInstanceAssetsMutation({
    fixedCacheKey: 'instances_assets_dynamic_ribbon',
  });

  useEffect(() => {
    trigger({ instanceId: resource.resourceId });
  }, []);

  return (
    <DynamicRibbon
      isLoading={isLoading}
      response={data}
      submitCallback={() => trigger({ instanceId: resource.resourceId })}
    />
  );
};
