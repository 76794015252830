import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import ArrowDownIcon from '#/assets/ArrowDownIcon.svg';

import AssetFileInput from '#/components/campaigns/campaignAssets/AssetFileInput';
import DownloadAssetsFiles from '#/components/campaigns/campaignAssets/DownloadAssetsFiles';
import DownloadTraffickingSheet from '#/components/campaigns/campaignAssets/DownloadTraffickingSheet';
import {
  accordionDetailsSx,
  accordionSummarySx,
  accordionSx,
  useTacticsAssetsStyle,
} from '#/components/campaigns/campaignAssets/TacticsAssets.style';
import TraffickingSheetLink from '#/components/campaigns/campaignAssets/TraffickingSheetLink';
import AssetProduct from '#/components/campaigns/campaignAssets/assetsProduct/AssetProduct';
import AlertError from '#/components/common/Alert/AlertError/AlertError';
import NewButton from '#/components/common/button/NewButton';
import Loader from '#/components/common/loader/loader';
import { CheckBoxListInput } from '#/components/common/tacticMetadataForm/inputs/CheckBoxListInput';
import { CheckBoxSingleOptionInput } from '#/components/common/tacticMetadataForm/inputs/CheckBoxSingleOptionInput';
import { DropdownInput } from '#/components/common/tacticMetadataForm/inputs/DropdownInput';
import { NumericInput } from '#/components/common/tacticMetadataForm/inputs/NumericInput';
import { RadioBoxInput } from '#/components/common/tacticMetadataForm/inputs/RadioBoxInput';
import { TextAreaInput } from '#/components/common/tacticMetadataForm/inputs/TextAreaInput';
import { TextInput } from '#/components/common/tacticMetadataForm/inputs/TextInput';
import { extractInputsFromMetadata, getFormResetValues } from '#/components/common/tacticMetadataForm/utils';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { useAssetMetadataForm } from '#/hooks/forms/assetsMetadata/useAssetsMetadataForm';
import useGetResource from '#/hooks/useGetResource';

import { IInstanceTactics } from '#/interfaces/forms/tacticAssets';
import { InputTypes } from '#/interfaces/tacticMetadata/tacticMetadata';

import { useGetInstanceQuery } from '#/store/api/campaigns/instances';
import { useGetInstanceAssetMetadataQuery } from '#/store/api/campaigns/tacticAssetForm';

import PermissionWrapper from '#/utils/PermissionWrapper';

import EditIcon from '../../../assets/Edit.svg';

interface ICampaignAssetsFormProps {
  tactic: IInstanceTactics;
}
const CampaignAssetsForm: FC<ICampaignAssetsFormProps> = ({ tactic }) => {
  const {
    data: tacticsMetadataData,
    refetch,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetInstanceAssetMetadataQuery({ id: tactic.instanceTacticId });
  const { resourceId } = useGetResource();
  const { data: headerData } = useGetInstanceQuery({ instanceId: resourceId });
  const canEdit = headerData?.instanceStatus !== 'COMPLETE' && headerData?.instanceStatus !== 'CANCELED';

  const classes = useTacticsAssetsStyle();
  const inputFields = tacticsMetadataData ? extractInputsFromMetadata(tacticsMetadataData) : [];
  const [editMode, setEditMode] = useState(false);
  const {
    onSubmit,
    register,
    errors,
    control,
    watch,
    reset,
    setValue,
    isLoading: saveIsLoading,
    error,
  } = useAssetMetadataForm(inputFields, tactic.instanceTacticId, async () => {
    await refetch().unwrap();
    setEditMode(false);
  });
  const returnInputs = inputFields.map((data) => {
    let input: JSX.Element = <></>;
    switch (data.inputType) {
      case InputTypes.TEXT:
        input = (
          <Box className={classes.inputContainer}>
            <TextInput editMode={editMode} errors={errors} helperTextSubtitle input={data} register={register} />
          </Box>
        );
        break;
      case InputTypes.AREA:
        input = (
          <Box className={classes.inputContainer}>
            <TextAreaInput editMode={editMode} errors={errors} helperTextSubtitle input={data} register={register} />
          </Box>
        );
        break;
      case InputTypes.NUMBER:
        input = (
          <Box className={classes.inputContainer}>
            <NumericInput
              editMode={editMode}
              errors={errors}
              helperTextSubtitle
              input={data}
              register={register}
              setValue={setValue}
            />
          </Box>
        );
        break;
      case InputTypes.DROPDOWN:
        input = (
          <Box className={classes.inputContainer}>
            <DropdownInput control={control} editMode={editMode} errors={errors} helperTextSubtitle input={data} />
          </Box>
        );
        break;
      case InputTypes.CHECKBOX_LIST:
        input = (
          <Box className={classes.inputContainer}>
            <CheckBoxListInput
              control={control}
              editMode={editMode}
              errors={errors}
              helperTextSubtitle
              input={data}
              watch={watch}
            />
          </Box>
        );
        break;
      case InputTypes.SINGLE_OPTION_CHECKBOX:
        input = (
          <Box className={classes.inputContainer}>
            <CheckBoxSingleOptionInput
              control={control}
              editMode={editMode}
              helperTextSubtitle
              input={data}
              watch={watch}
            />
          </Box>
        );
        break;
      case InputTypes.RADIO:
        input = (
          <Box className={classes.inputContainer}>
            <RadioBoxInput
              control={control}
              editMode={editMode}
              errors={errors}
              helperTextSubtitle
              input={data}
              watch={watch}
            />
          </Box>
        );
        break;
      case InputTypes.FILE:
        input = (
          <Box className={classes.inputContainer}>
            <AssetFileInput
              editMode={editMode}
              errors={errors}
              input={data}
              setValue={setValue}
              tacticId={tactic.instanceTacticId}
              watch={watch}
            />
          </Box>
        );
        break;
      case InputTypes.PRODUCT_SELECTOR:
        input = (
          <Box className={classes.inputContainer}>
            <AssetProduct
              editMode={editMode}
              errors={errors}
              input={data}
              instanceTacticId={tactic.instanceTacticId}
              setValue={setValue}
              watch={watch}
            />
          </Box>
        );
        break;
    }

    return input;
  });

  const onCancel = () => {
    if (!tacticsMetadataData) {
      return;
    }
    const defaultFields = getFormResetValues(tacticsMetadataData);
    reset(defaultFields);
    setEditMode(false);
  };
  useEffect(() => {
    if (!tacticsMetadataData || !isSuccess) {
      return;
    }
    const defaultFields = getFormResetValues(tacticsMetadataData);
    reset(defaultFields);
  }, [tacticsMetadataData?.metadata?.length, isSuccess]);

  return (
    <Accordion sx={accordionSx}>
      <Box className={classes.assetHeader}>
        <AccordionSummary expandIcon={<img src={ArrowDownIcon} />} sx={accordionSummarySx}>
          <Box className={classes.headerText}>
            <TypographyWithFontFamily variant={'H6_Base'}>{tactic.tactic}</TypographyWithFontFamily>
            <TypographyWithFontFamily>
              {tactic.channel} / {tactic.subChannel}: {tactic.channelPartnerName}
            </TypographyWithFontFamily>
            <TypographyWithFontFamily>Due Date: {tactic.creativeDueDate}</TypographyWithFontFamily>
          </Box>
        </AccordionSummary>
        <AccordionActions>
          <TraffickingSheetLink tacticId={tactic.instanceTacticId} />
          <DownloadTraffickingSheet tacticId={tactic.instanceTacticId} />
          <DownloadAssetsFiles tacticId={tactic.instanceTacticId} />
        </AccordionActions>
      </Box>
      <AlertError error={error} />

      <AccordionDetails sx={accordionDetailsSx}>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <form encType="multipart/form-data" onSubmit={onSubmit}>
            {canEdit && (
              <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.CAMPAIGN_INSTANCE_ASSETS_WRITE]}>
                <Box className={classes.topButtonsContainer}>
                  {editMode ? (
                    <>
                      <NewButton onClick={onCancel} text={'Cancel'} variant={'Secondary'} />
                      <NewButton loading={saveIsLoading} text={'Save'} type={'submit'} />
                    </>
                  ) : (
                    <NewButton
                      icon={EditIcon}
                      iconPosition={'left'}
                      onClick={() => setEditMode(true)}
                      text={'Edit'}
                      variant={'Ghost'}
                    />
                  )}
                </Box>
              </PermissionWrapper>
            )}
            {returnInputs}
          </form>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignAssetsForm;
