import { Divider, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '#/hooks';

import { USER_TYPES } from '#/utils/UserTypeWrapper';

import { getCompanyProfileInfo } from '../../../api/users';
import { ALL_USER_PERMISSION } from '../../../constants/permissions/userPermissions';
import { useRequest } from '../../../hooks/useRequest';
import { IUserCompanyData } from '../../../interfaces/user/user';
import ImageDownloadUpload from '../downloadUpload/ImageDownloadUpload';
import { TypographyWithFontFamily } from '../typography/TypographyWithFontFamily';
import { dividerSx, menuTabsSx, useMainMenuStyles } from './MainMenu.style';
import { MainMenuItem } from './MainMenuItem';
import { UserProfile } from './UserProfile';
import { menuElements } from './menuElements';
import { menuElementsBrand } from './menuElementsBrand';

export const MainMenu = () => {
  const { permissions } = useAppSelector((state) => state.user);
  const user = useAppSelector((state) => state.user);
  const filteredMenuElements = (user.user?.userType === USER_TYPES.BRAND ? menuElementsBrand : menuElements).filter(
    (element) => element.permission === ALL_USER_PERMISSION || permissions.includes(element.permission),
  );
  const classes = useMainMenuStyles();
  const menuItems = filteredMenuElements.map((element) => <MainMenuItem key={element.route} item={element} />);
  const [aspectRatio, setAspectRation] = useState<{ aspectRatio: string }>();
  const [data, setData] = useState<IUserCompanyData>();
  const getCompanies = useMemo(() => getCompanyProfileInfo(), []);
  const { response } = useRequest(getCompanies);

  useEffect(() => {
    setData(response?.data);
  }, [response]);

  const { companyId, companyName, logoId } = data || {};

  const getAspectRatio = (img) => {
    const w = img.target.width;
    const h = img.target.height;
    let aspectRatio: number;

    if (w > h) {
      aspectRatio = w / h;
    } else {
      aspectRatio = h / w;
    }

    return aspectRatio > 2 ? setAspectRation({ aspectRatio: '30/6' }) : setAspectRation({ aspectRatio: '20/6' });
  };
  return (
    <div className={classes.menuContainer} data-testId="sidemenu-container">
      <div className={classes.menuItemsContainer}>
        <Tabs orientation="vertical" sx={menuTabsSx}>
          <Box className={classes.topImage} sx={aspectRatio ? { '&& > img': aspectRatio } : undefined}>
            <img alt="default logo" onLoad={getAspectRatio} src={`/custom/DefaultLogo.png`} />
          </Box>
          <Divider sx={dividerSx} />
          {menuItems}
        </Tabs>
      </div>
      {companyId && (
        <Box className={classes.companyLogo}>
          <Box className={classes.companyImage}>
            <ImageDownloadUpload fileId={logoId} renderImageTag />
          </Box>
          <TypographyWithFontFamily cName={classes.companyName}>{companyName}</TypographyWithFontFamily>
        </Box>
      )}
      <UserProfile />
    </div>
  );
};
