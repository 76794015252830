import { Middleware } from 'redux';

import { oktaConfig } from '#/constants/authentication/oktaConfig';

import { user } from '#/store/slices';

import log from '#/utils/log';

const authMiddleware: Middleware = () => {
  return (next) => async (action) => {
    if (user.actions.logout.match(action)) {
      try {
        localStorage.removeItem('okta-token-storage');
        await oktaConfig.signOut();
      } catch (error) {
        log.error('Error logging out', error);
      }
      return;
    }

    return next(action);
  };
};

export default authMiddleware;
