import { configureStore } from '@reduxjs/toolkit';

import { baseApi } from '#/store/base';
import { communicationsPromoteSlice, messagesSlice, userSlice } from '#/store/slices';

import { authMiddleware, webSocketMiddleware } from './middleware';

export const reducers = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [communicationsPromoteSlice.name]: communicationsPromoteSlice.reducer,
    [messagesSlice.name]: messagesSlice.reducer,
    [userSlice.name]: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware, webSocketMiddleware, authMiddleware),
});

export type AppDispatch = typeof reducers.dispatch;
export type RootState = ReturnType<typeof reducers.getState>;
