import { Box, Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import { FC, useState } from 'react';

import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import { useAddTireForm } from '#/hooks/forms/campaigns/useAddTireForm';

import { IChannelAndTacticsResponseTier } from '#/interfaces/forms/campaigns';

import { createFormatterWithReverse, currencyFormatter, extractNumber } from '#/utils/textFormatters';

import { useCampaignTierStyle } from '../../CampaignTier.style';
import NewCampaignTierTacticsTableBrandInstances from './NewCampaignTierTacticsTableBrandInstances';

interface ICampaignTierBrandInstances {
  tier?: IChannelAndTacticsResponseTier;
  tierRefetch?: () => void;
}
const CampaignTierBrandInstances: FC<ICampaignTierBrandInstances> = ({ tier, tierRefetch }) => {
  const [editMode, setEditMode] = useState(false);
  const { errors, register, onSubmitEdit, setValue } = useAddTireForm(() => setEditMode(false), tier);

  const classes = useCampaignTierStyle();

  return (
    <Box className={classes.container}>
      <Box className={classes.tierContainer}>
        <Box>
          <form className={classes.form} onSubmit={onSubmitEdit}>
            <Grid columnGap={'0.5rem'} container>
              <Grid item xs={2.9}>
                <TextFieldOld
                  editMode={editMode}
                  error={errors.tierName?.message}
                  label="TIER NAME"
                  register={register('tierName')}
                  value={tier?.tierName}
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled={!editMode}
                  error={errors.targetBudget?.message}
                  formHooks={{
                    register: register('targetBudget'),
                    setValue: (value) => setValue('targetBudget', parseFloat(value)),
                  }}
                  formatters={{
                    preview: currencyFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                  }}
                  label="TARGET BUDGET"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box className={classes.tacticsTableContainer}>
          <NewCampaignTierTacticsTableBrandInstances
            tactics={tier?.tactics}
            tierId={tier?.tierId}
            tierRefetch={tierRefetch}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default CampaignTierBrandInstances;
