import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { oktaConfig } from '#/constants/authentication/oktaConfig';
import { BASE_PATH, HOST_URL } from '#/constants/general';

interface Link {
  rel: string;
  href: string;
  titles: {
    und: string;
  };
  properties: {
    'okta:idp:type': string;
    'okta:idp:id'?: string;
  };
}

interface NonFederatedOktaResponse {
  subject: string;
  links: Link[];
  headers: {
    'cache-control': string;
    'content-type': string;
    expires: string;
    pragma: string;
  };
}

interface FederatedOktaResponse {
  subject: string;
  links: Link[];
}

const isFederated = (res: NonFederatedOktaResponse | FederatedOktaResponse) => {
  return 'okta:idp:id' in res.links[0].properties;
};

export const useLoginForm = () => {
  const [email, setEmail] = useState('');
  const [isFederatedUser, setIsFederatedUser] = useState(true);
  const { oktaAuth } = useOktaAuth();
  const [isLoginErrorVisible, toggleLoginError] = useState(false);

  const schema = yup
    .object()
    .shape({
      username: yup.string().email('Username must be a valid email').required('Please enter username'),
      password: yup.string().required('Please enter password'),
    })
    .required();

  const {
    formState: { errors },
    handleSubmit,
    ...methods
  } = useForm<{ username: string; password: string }>({
    resolver: yupResolver(schema),
  });

  const webfingerHandler = (user: string, nonFederatedCallback: () => void) => {
    oktaConfig
      .webfinger({
        resource: `acct:${user}`,
        rel: 'okta:idp',
      })
      //@ts-expect-error this is the response type from OKTA about the user
      .then(function (res: NonFederatedOktaResponse | FederatedOktaResponse) {
        if (isFederated(res)) {
          oktaAuth.signInWithRedirect({
            idp: res?.links[0]?.properties?.['okta:idp:id'],
            originalUri: `${HOST_URL}${BASE_PATH}`,
            loginHint: user,
          });
        } else {
          nonFederatedCallback();
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  const checkForFederation = () => {
    webfingerHandler(email, () => {
      setIsFederatedUser(false);
      methods.setValue('username', email);
    });
  };

  const onSubmit = handleSubmit((formData: { username: string; password: string }) => {
    webfingerHandler(formData.username, () => {
      oktaAuth
        .signInWithCredentials(formData)
        .then((res) => {
          toggleLoginError(false);
          const sessionToken = res.sessionToken;
          if (!sessionToken) {
            throw new Error('authentication process failed');
          }

          oktaAuth.signInWithRedirect({
            originalUri: `${HOST_URL}${BASE_PATH}`,
            sessionToken: sessionToken,
          });
        })
        .catch((err) => {
          toggleLoginError(true);
          throw new Error('An error has occurred: ', err);
        });
    });
  });

  const hasError = errors.username || errors.password;

  const backHandler = () => {
    setEmail('');
    setIsFederatedUser(true);
  };

  return {
    email,
    isLoginErrorVisible,
    isFederatedUser,
    setEmail,
    backHandler: backHandler,
    errors,
    hasError,
    checkForFederation,
    onSubmit,
    errorFlag: false,
    ...methods,
  };
};
