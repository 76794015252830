import { Box, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useDataCy } from '#/hooks/useDataCy';

import { getCampaignInstanceMarketing } from '../../../../../api/campaign/campaigns';
import { USER_PERMISSIONS } from '../../../../../constants/permissions/userPermissions';
import { THEME } from '../../../../../constants/theme/constants';
import { useOverviewEditMarketing } from '../../../../../hooks/forms/campaigns/use-overview-edit-marketing';
import { useRequest } from '../../../../../hooks/useRequest';
import {
  textAreaEditMode,
  textAreaReadOnlyMode,
  upperCaseSx,
  useCampaignOverviewMarketingStyles,
} from '../../../../campaigns/CampaignOverviewMarketing.style';
import { DeprecatedTopSection } from '../../../../campaigns/topSection/DeprecatedTopSection';
import ImageDownloadUpload from '../../../../common/downloadUpload/ImageDownloadUpload';
import MultipleDownloadUpload from '../../../../common/downloadUpload/MultipleDownloadUpload';
import SingleDownloadUpload from '../../../../common/downloadUpload/SingleDownloadUpload';
import Loader from '../../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../../common/typography/TypographyWithFontFamily';

const CampaignInstanceMarketing = () => {
  const { instanceId } = useParams();
  const generateDataCy = useDataCy();

  const [editMode, setEditMode] = useState(false);
  const { errors, register, reset, setValue, onSubmit, watch } = useOverviewEditMarketing(() => setEditMode(false));
  const getCampaignMarketingAction = useMemo(() => getCampaignInstanceMarketing(instanceId), [editMode]);
  const { response, loading } = useRequest(getCampaignMarketingAction);
  const { promotionalFileId, additionalFilesId, thumbnailId, summary } = response?.data || {};
  useEffect(() => {
    reset({
      additionalFilesId: additionalFilesId || [],
      promotionalFileId: promotionalFileId,
      thumbnailId: thumbnailId,
      summary: summary,
    });
  }, [additionalFilesId, promotionalFileId, thumbnailId, summary, editMode]);
  const classes = useCampaignOverviewMarketingStyles();
  if (loading) return <Loader />;

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.marketingContainer} data-cy={generateDataCy('box', 'CampaignOverviewMarketing')}>
          <DeprecatedTopSection
            editMode={editMode}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_OVERVIEW_MARKETING_WRITE}
            sectionName="Marketing"
            setEditMode={setEditMode}
          />
          <Box className={classes.marketingSectionsContainer}>
            <Box className={classes.marketingSection}>
              <Box>
                <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                  Campaign Promotional Files
                </TypographyWithFontFamily>
                <SingleDownloadUpload
                  editMode={editMode}
                  fileId={watch('promotionalFileId')}
                  registerName="promotionalFileId"
                  setValue={setValue}
                  uploadLink={'/campaign/promotional-file'}
                />
              </Box>

              <Box>
                <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                  Additional files
                </TypographyWithFontFamily>
                <MultipleDownloadUpload
                  editMode={editMode}
                  extensions={['.pdf']}
                  filesIds={watch('additionalFilesId')}
                  registerName="additionalFilesId"
                  setValue={setValue}
                  uploadLink={`/campaign/marketing-additional-files`}
                />
              </Box>
              <Box>
                <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                  Campaign Summary (For Brands)
                </TypographyWithFontFamily>
                <TextField
                  date-cy="Campaign Summary For Brands"
                  helperText={errors.summary?.message?.toString()}
                  {...register('summary')}
                  disabled={!editMode}
                  fullWidth
                  multiline
                  rows={4}
                  sx={editMode ? textAreaEditMode : textAreaReadOnlyMode}
                />
              </Box>
            </Box>
            <Box className={classes.marketingSection}>
              <TypographyWithFontFamily
                color={THEME.PALETTES.TEXT.Gray}
                sx={{ textTransform: 'uppercase' }}
                variant="Label_Base"
              >
                Campaign Thumbnail
              </TypographyWithFontFamily>
              <ImageDownloadUpload
                editMode={editMode}
                fileId={watch('thumbnailId')}
                registerName="thumbnailId"
                setValue={setValue}
                uploadLink={'/campaign/thumbnail'}
              />

              {!editMode && !watch('thumbnailId') && (
                <TypographyWithFontFamily variant="Small_Bold">No thumbnail yet</TypographyWithFontFamily>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};
export default CampaignInstanceMarketing;
