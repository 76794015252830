import { CampaignAssets } from '#/components/campaigns/campaignAssets/CampaignAssets';
import BrandOrders from '#/components/features/campaigns/BrandOrders/BrandOrders';
import { Campaigns } from '#/components/features/campaigns/Campaigns/Campaigns';
import CampaignsHeader from '#/components/features/campaigns/CampaignsHeader';
import EngagementStats from '#/components/features/campaigns/EngagementStats';
import { CampaignInstanceChannelsAndTactics } from '#/components/features/campaigns/instances/channelsAndTactics/CampaignInstanceChannelsAndtactics';
import { CampaignInstances } from '#/components/features/campaigns/instances/list/CampaignInstances';
import { PromoteCampaignsContainer } from '#/components/features/communications/PromoteCampaignsContainer';
import { Partners } from '#/components/features/partners/Partners';
import { Agency } from '#/components/features/partners/agencies/Agency';
import CompaniesContainer from '#/components/features/partners/companies/CompaniesContainer';
import { Communications } from '#/components/features/promote/Promote';
import { Reports } from '#/components/features/reports/Reports';
import { ReportDetailsContainer } from '#/components/features/reports/ReportsDetailsContainer';
import DataJobs from '#/components/features/settings/dataJobs/DataJobs';
import { ReportAdminPage } from '#/components/features/settings/reportAdminPage/ReportAdminPage';
import Workflows from '#/components/features/settings/workflows/Workflows';
import { UserProfile } from '#/components/features/userProfile/UserProfile';
import { UserProfileAccount } from '#/components/features/userProfile/UserProfileAccount';
import { UserProfileNotifications } from '#/components/features/userProfile/UserProfileNotifications';

import { USER_TYPES } from '#/utils/UserTypeWrapper';

import CalendarContainer from '../../components/Calendar/CalendarContainer';
import CampaignsProductMix from '../../components/campaigns/CampaignsProductMix';
import CampaignBrandOrderPage from '../../components/campaigns/campaignAssets/brandAssets/CampaignBrandOrderPage';
import CampaignHistory from '../../components/features/campaigns/CampaignHistory';
import CampaignHistoryInstance from '../../components/features/campaigns/CampaignHistoryInstance';
import CampaignOverview from '../../components/features/campaigns/CampaignOverview';
import CampaignsView from '../../components/features/campaigns/CampaignsView';
import { ChannelsAndTactics as CampaignChannelsAndTactics } from '../../components/features/campaigns/campaign/channelsAndTactics/ChannelsAndTactics';
import CampaignBrands from '../../components/features/campaigns/campaignBrands';
import CampaignsSingleHeader from '../../components/features/campaigns/campaignsSingleHeader';
import CampaignInstanceHeader from '../../components/features/campaigns/instances/CampaignInstanceHeader';
import CampaignInstanceOverview from '../../components/features/campaigns/instances/CampaignInstanceOverview';
import CommunicationPromoteReview from '../../components/features/communications/modalPages/communicationsPromoteReview/CommunicationsPromoteReview';
import CommunicationsSelectBrands from '../../components/features/communications/modalPages/communicationsSelectBrands/CommunicationsSelectBrands';
import CommunicationsSelectMarketing from '../../components/features/communications/modalPages/communicationsSelectMarketing/CommunicationsSelectMarketing';
import CommunicationsSelectRecipients from '../../components/features/communications/modalPages/communicationsSelectRecipients/communicationsSelectRecipients';
import Dashboard from '../../components/features/dashboard/Dashboard';
import { default as Brand, default as SingleCompany } from '../../components/features/partners/brands/Brand';
import ChannelPartner from '../../components/features/partners/channelPartners/ChannelPartner';
import ChannelPartnerSingle from '../../components/features/partners/channelPartners/ChannelPartnerSingle';
import UserAccess from '../../components/features/partners/userAccess/UserAccess';
import PartnerUsers from '../../components/features/partners/users/PartnerUsers';
import PartnerUsersSingle from '../../components/features/partners/users/PartnerUsersSingle';
import SettingsSubmenu from '../../components/features/settings/SettingsSubmenu';
import ChannelsAndTactics from '../../components/features/settings/channelsAndTactics/ChannelsAndTactics';
import GeneralSettings from '../../components/features/settings/dataAndCalendar/GeneralSettings';
import ProductMixGlobal from '../../components/features/settings/productMix/ProductMixGlobal/ProductMixGlobal';
import UsersSettings from '../../components/features/settings/userSettings/usersSettings';
import TasksOverview from '../../components/features/tasks/TaskOverview';
import TasksHeader from '../../components/features/tasks/TasksHeader';
import TasksSingleHeader from '../../components/features/tasks/TasksSingleHeader';
import TasksView from '../../components/features/tasks/TasksView';
import { USER_PERMISSIONS } from '../permissions/userPermissions';
import { ROUTE_PATHS } from './routePaths';

export interface IRoute {
  path: string;
  component: React.ElementType;
  permission?: string;
  allowedUserTypes?: USER_TYPES[];
  //this will eventually replace permission, when we will want to use more specific permissions
  permissions?: [
    {
      name: string;
      actions: string[];
    },
  ];
}

export interface IMainRoute extends IRoute {
  path: string;
  children?: IRoute[];
}

export const routesList: IMainRoute[] = [
  {
    path: ROUTE_PATHS.ROOT,
    component: Dashboard,
    // every user will be able to see this
  },
  {
    path: ROUTE_PATHS.BRAND_SINGLE,
    component: SingleCompany,
    permission: USER_PERMISSIONS.BRANDS,
  },
  {
    path: ROUTE_PATHS.TASKS,
    component: TasksHeader,
    permission: USER_PERMISSIONS.WORKFLOW,
    children: [
      {
        path: ROUTE_PATHS.TASKS_ALL,
        component: TasksView,
      },
      {
        path: ROUTE_PATHS.TASKS_OPEN,
        component: TasksView,
      },
      {
        path: ROUTE_PATHS.TASKS_CLOSED,
        component: TasksView,
      },
    ],
  },
  {
    path: ROUTE_PATHS.TASKS,
    component: TasksSingleHeader,
    permission: USER_PERMISSIONS.WORKFLOW,
    children: [
      {
        path: ROUTE_PATHS.TASKS_OVERVIEW,
        component: TasksOverview,
      },
    ],
  },
  {
    path: ROUTE_PATHS.CALENDAR,
    component: CalendarContainer,
    permission: USER_PERMISSIONS.CAMPAIGN_OVERVIEW,
  },
  {
    path: ROUTE_PATHS.CAMPAIGNS,
    component: CampaignsHeader,
    permission: USER_PERMISSIONS.CAMPAIGNS,
    children: [
      {
        path: ROUTE_PATHS.CAMPAIGNS_ALL,
        allowedUserTypes: [USER_TYPES.CHANNEL_PARTNER, USER_TYPES.RETAILER, USER_TYPES.AGENCY],
        component: Campaigns,
      },
      {
        path: ROUTE_PATHS.BRAND_ORDERS,
        component: BrandOrders,
      },
      {
        path: ROUTE_PATHS.CAMPAIGNS_ACTIVE,
        component: CampaignsView,
      },
      {
        path: ROUTE_PATHS.CAMPAIGNS_PENDING,
        component: CampaignsView,
      },
      {
        path: ROUTE_PATHS.CAMPAIGNS_COMPLETE,
        component: CampaignsView,
      },
      {
        path: ROUTE_PATHS.CAMPAIGNS_REJECTED,
        component: CampaignsView,
      },
      {
        path: ROUTE_PATHS.CAMPAIGNS_CANCELED,
        component: CampaignsView,
      },
    ],
  },
  {
    path: ROUTE_PATHS.SETTINGS,
    component: SettingsSubmenu,
    permission: USER_PERMISSIONS.SETTINGS,
    children: [
      {
        path: ROUTE_PATHS.SETTINGS_CHANNELS_AND_TACTICS,
        component: ChannelsAndTactics,
      },
      {
        path: ROUTE_PATHS.SETTINGS_USER,
        component: UsersSettings,
      },
      {
        path: ROUTE_PATHS.SETTINGS_REPORT,
        component: ReportAdminPage,
      },
      {
        path: ROUTE_PATHS.SETTINGS_JOBS,
        component: GeneralSettings,
      },
      {
        path: ROUTE_PATHS.SETTINGS_DATA_JOBS,
        component: DataJobs,
      },
      {
        path: ROUTE_PATHS.SETTINGS_WORKFLOWS,
        component: Workflows,
      },
      { path: ROUTE_PATHS.PRODUCT_MIX, component: ProductMixGlobal },
    ],
  },
  {
    path: ROUTE_PATHS.CAMPAIGNS,
    component: CampaignsSingleHeader,
    permission: USER_PERMISSIONS.CAMPAIGNS,
    allowedUserTypes: [USER_TYPES.CHANNEL_PARTNER, USER_TYPES.RETAILER, USER_TYPES.AGENCY],
    children: [
      {
        path: ROUTE_PATHS.CAMPAIGN_OVERVIEW,
        component: CampaignOverview,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_CHANNEL_TACTICS,
        component: CampaignChannelsAndTactics,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_TARGET_PRODUCT_MIX,
        component: CampaignsProductMix,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_BRANDS,
        component: CampaignBrands,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_INSTANCES,
        component: CampaignInstances,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_HISTORY,
        component: CampaignHistory,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_ENGAGEMENT_STATS,
        component: EngagementStats,
      },
    ],
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_INSTANCE,
    component: CampaignInstanceHeader,
    children: [
      {
        path: ROUTE_PATHS.CAMPAIGN_INSTANCES_OVERVIEW,
        component: CampaignInstanceOverview,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_INSTANCES_CHANNEL_TACTICS,
        component: CampaignInstanceChannelsAndTactics,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_INSTANCES_ASSETS,
        component: CampaignAssets,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_INSTANCE_HISTORY,
        component: CampaignHistoryInstance,
      },
      {
        path: ROUTE_PATHS.CAMPAIGN_BRAND_ORDER_DETAILS,
        component: CampaignBrandOrderPage,
      },
    ],
  },
  {
    path: ROUTE_PATHS.COMMUNICATIONS,
    component: Communications,
    permission: USER_PERMISSIONS.PROMOTE,
    children: [
      {
        path: ROUTE_PATHS.CAMPAIGNS_PROMOTE,
        component: PromoteCampaignsContainer,
      },
      {
        path: ROUTE_PATHS.CAMPAIGNS_INVITE_BRAND,
        component: CommunicationsSelectBrands,
      },
      {
        path: ROUTE_PATHS.PROMOTE_INVITE_RECIPIENTS,
        component: CommunicationsSelectRecipients,
      },
      {
        path: ROUTE_PATHS.PROMOTE_MARKETING,
        component: CommunicationsSelectMarketing,
      },
      {
        path: ROUTE_PATHS.PROMOTE_REVIEW,
        component: CommunicationPromoteReview,
      },
    ],
  },
  {
    path: ROUTE_PATHS.REPORTS,
    component: Reports,
    permission: USER_PERMISSIONS.REPORTS,
    children: [
      {
        path: ROUTE_PATHS.REPORT_DETAILS,
        component: ReportDetailsContainer,
      },
    ],
  },
  {
    path: ROUTE_PATHS.PARTNERS,
    component: Partners,
    permission: USER_PERMISSIONS.PARTNERS,
    children: [
      {
        path: ROUTE_PATHS.COMPANIES,
        component: CompaniesContainer,
      },
      {
        path: ROUTE_PATHS.BRAND_SINGLE,
        component: Brand,
        permission: USER_PERMISSIONS.BRANDS,
      },
      {
        path: ROUTE_PATHS.PARTNERS_AGENCY,
        component: Agency,
      },
      {
        path: ROUTE_PATHS.PARTNERS_USERS,
        component: PartnerUsers,
      },
      {
        path: ROUTE_PATHS.PARTNERS_USERS_SINGLE,
        component: PartnerUsersSingle,
      },
      { path: ROUTE_PATHS.USER_ACCESS, component: UserAccess },
      { path: ROUTE_PATHS.CHANNEL_PARTNERS, component: ChannelPartner },
      {
        path: ROUTE_PATHS.CHANNEL_PARTNERS_SINGLE,
        component: ChannelPartnerSingle,
      },
    ],
  },
  {
    path: ROUTE_PATHS.ACCOUNT,
    component: UserProfile,
    children: [
      {
        path: ROUTE_PATHS.ACCOUNT_MANAGEMENT,
        component: UserProfileAccount,
      },
      {
        path: ROUTE_PATHS.ACCOUNT_NOTIFICATIONS,
        component: UserProfileNotifications,
      },
    ],
  },
];
