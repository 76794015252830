import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import TurbyneLogo from '#/assets/TurbyneLogo.svg';

import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';
import LoginForm from '#/components/features/login/LoginForm';
import { oktaConfig } from '#/constants/authentication/oktaConfig';

import { user } from '#/store/slices';

import { useLoginStyles, Container, ItemContainer, FormContainer, HeadLogo } from './Login.style';

export const Login = () => {
  const classes = useLoginStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSuccess } = useSnackbarSuccess();
  const requestSent = useLocation()?.state?.requestSent;
  useEffect(() => {
    if (requestSent) {
      setSuccess('Account request submitted');
      window.history.replaceState({}, '');
    }
  }, []);
  useEffect(() => {
    const redirectOnAuth = async () => {
      const isAuth = await oktaConfig.isAuthenticated();
      if (isAuth) {
        dispatch(user.actions.setAuthToken(oktaConfig.getAccessToken()));
        navigate('/');
      }
    };
    redirectOnAuth();
  }, []);

  return (
    <Container>
      <ItemContainer>
        <FormContainer>
          <div className={classes.formHead}>
            <p>Welcome To</p>
            <HeadLogo>
              <img alt="defaultlogo" src={`/custom/DefaultLogo.png`} />
            </HeadLogo>
          </div>
          <div className={classes.formContent}>
            <p className={classes.title}>Sign In</p>
            <LoginForm />
            <div className={classes.linksContainer}>
              <a className={classes.url} href="/request-account">
                Request an account
              </a>
              <a className={classes.url} href="/">
                Need help signing in?
              </a>
            </div>
          </div>
        </FormContainer>
        <img alt="defaultlogo" className={classes.logo} src={TurbyneLogo} />
      </ItemContainer>
    </Container>
  );
};
export default Login;
