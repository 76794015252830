import { Box } from '@mui/material';
import React from 'react';

import { downloadAllAssets } from '#/api/campaign/campaignInstance';

import CampaignAssetsForm from '#/components/campaigns/campaignAssets/CampaignAssetsForm';
import DownloadTraffickingSheet from '#/components/campaigns/campaignAssets/DownloadTraffickingSheet';
import TraffickingSheetLink from '#/components/campaigns/campaignAssets/TraffickingSheetLink';
import Loader from '#/components/common/loader/loader';

import useGetResource from '#/hooks/useGetResource';

import { useGetInstanceTacticsQuery } from '#/store/api/campaigns/tacticAssetForm';

import { THEME } from '../../../constants/theme/constants';
import NewButton from '../../common/button/NewButton';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { useTacticsAssetsStyle } from './TacticsAssets.style';

const TacticsAssets = () => {
  const classes = useTacticsAssetsStyle();
  const { resourceId } = useGetResource();
  const { data: tactics, isLoading } = useGetInstanceTacticsQuery({ id: resourceId });
  const onDownloadAll = async (instanceId: string | undefined) => {
    const { headers, data } = await downloadAllAssets(instanceId);
    const name = headers['content-disposition']?.match(/filename=([^"]+)/)?.[1] || 'assets.zip';
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = name;
    link.click();
    URL.revokeObjectURL(link.href);
  };
  const onClickDownloadAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onDownloadAll(resourceId);
  };

  if (!(tactics && tactics?.length > 0)) {
    return <></>;
  }
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box className={classes.tacticsAssetsContainer}>
          <Box className={classes.tacticsHeaderContainer}>
            <TypographyWithFontFamily color={THEME.PALETTES.UI.Neutral['900']} variant="H4_Base">
              Tactics
            </TypographyWithFontFamily>
            <Box className={classes.approveButtonContainer}>
              <TraffickingSheetLink />
              <DownloadTraffickingSheet />
              <NewButton onClick={onClickDownloadAll} text="Download Files" variant="Secondary" />
            </Box>
          </Box>
          {tactics?.length > 0 ? (
            <> {tactics?.map((tactic) => <CampaignAssetsForm key={tactic.instanceTacticId} tactic={tactic} />)}</>
          ) : (
            <TypographyWithFontFamily>No Asset Tactics</TypographyWithFontFamily>
          )}
        </Box>
      )}
    </>
  );
};

export default TacticsAssets;
