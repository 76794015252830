import PartnersIcon from '../../../../assets/BluePartnersIcon.svg';
import ReportsIcon from '../../../../assets/BlueReportsIcon.svg';
import { IQuickLinks } from '../../../../interfaces/dashboard/quickLinks';

export const retailerLinks: IQuickLinks[] = [
  {
    name: 'Reports',
    url: '/reports',
    icon: ReportsIcon,
  },
  {
    name: 'Partners / Users',
    url: '/partners/users',
    icon: PartnersIcon,
  },
  {
    name: 'Partners / User Access Requests',
    url: '/partners/user-access',
    icon: PartnersIcon,
  },
];

export const brandLinks: IQuickLinks[] = [
  {
    name: 'Reports',
    url: '/reports',
    icon: ReportsIcon,
  },
];
