import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import Megaphone from '#/assets/Megaphone.svg';

import CampaignDetailBrand from '#/components/common/CampaignDetail/CampaignDetailBrand';
import Card from '#/components/common/card/card';
import DisplayPageName from '#/components/common/displayPageName/DisplayPageName';
import Loader from '#/components/common/loader/loader';
import { Pagination } from '#/components/common/pagination/Pagination';
import { usePendingInvitationsStyles } from '#/components/features/dashboard/PendingInvitations/PendingInvitations.styles';
import { BASE_PATH } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import useWindowDimensions from '#/hooks/useWindowDimensions';

import { useGetCampaignsBrandQuery } from '#/store/api/campaigns/campaigns';

const PendingInvitations = () => {
  const { width } = useWindowDimensions(500);
  const classes = usePendingInvitationsStyles();
  const [page, setPage] = useState(1);

  const numberOfRows = Math.floor(width / 625);
  const { data, isLoading, isFetching, refetch } = useGetCampaignsBrandQuery({
    pageNumber: page,
    numberOfRows: numberOfRows !== 0 ? numberOfRows : 1,
    searchQuery: "status='BRAND_SELECTION'",
  });

  const { BRAND_ORDERS, CAMPAIGNS } = ROUTE_PATHS;
  const headerLink = `${BASE_PATH}/${CAMPAIGNS}/${BRAND_ORDERS}`;
  const noResults = !data || data.campaigns.length === 0;

  useEffect(() => {
    setPage(1);
  }, [width]);

  if (isLoading) return <Loader />;

  return (
    <Card>
      <DisplayPageName
        dataLoading={isFetching}
        imgSrc={Megaphone}
        pageName="Pending Invitations"
        redirectTo={headerLink}
        refetch={refetch}
      />
      {noResults ? (
        'No Pending Invitations'
      ) : (
        <>
          <Box className={classes.cardsRow}>
            {data.campaigns.map((campaign) => (
              <Box key={campaign.campaignId} className={classes.cardsContainer}>
                <CampaignDetailBrand data={campaign} />
              </Box>
            ))}
          </Box>
          <Pagination
            itemsPerPage={5}
            page={data.currentIndex}
            setPage={setPage as React.Dispatch<React.SetStateAction<number>>}
            showTotalResults={false}
            totalPages={data.totalPages}
            totalResults={data.totalResults}
          />
        </>
      )}
    </Card>
  );
};
export default PendingInvitations;
