import { TableCell as MUTCell, Tooltip } from '@mui/material';
import classnames from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDataCy } from '#/hooks/useDataCy';

import { EditableCellTypes, ITableColumnsProps, TableCellTypes } from '#/interfaces/table/table';

import NewButton from '../../../button/NewButton';
import Checkbox from '../../../inputs/Checkbox/Checkbox';
import { tableDefaultPadding, tooltipSx, useTableStyles } from '../Table.style';
import { EditableInput } from './EditableInput';
import { IconsOptions } from './IconsOptions';
import { MoreOptions } from './MoreOptions';
import { getTableExtraParams } from './utils';

export const TableCell = <T,>({
  column,
  data,
  bodyCellClass,
  tablePadding,
}: {
  column: ITableColumnsProps<T>;
  data: T;
  bodyCellClass?: string;
  tablePadding?: string;
}): React.ReactNode => {
  const classes = useTableStyles();
  const navigate = useNavigate();
  const generateDataCy = useDataCy();

  const getCustomCell = useCallback(
    (data: T) => {
      switch (column.type) {
        case TableCellTypes.STRING: {
          const element = data[column.fieldName as keyof T] ? String(data[column.fieldName as keyof T]) : '';
          const formattedElement = column.textFormatter ? column.textFormatter(element) : element;
          if (column.linkable) {
            let redirectTo = '';
            if (column.composeUrl && column.urlIdentifier && column.actionElementIdentifier) {
              redirectTo = column.composeUrl(
                String(data[column.actionElementIdentifier as keyof T]),
                String(data[column.urlIdentifier as keyof T]),
              );
            } else if (column.baseUrl) {
              const dynamicUrlPath = column.actionElementIdentifier
                ? `/${String(data[column.actionElementIdentifier as keyof T])}`
                : '';
              redirectTo = `${column.baseUrl}${dynamicUrlPath}`;
            }
            if (column.urlExtraParams) {
              redirectTo += getTableExtraParams(column.urlExtraParams);
            }
            return (
              <p
                className={classnames(classes.link, column.itemClass || '')}
                data-cy={generateDataCy('p', `TableCell-${String(column.fieldName)}`)}
                onClick={() => navigate(redirectTo)}
              >
                {formattedElement}
              </p>
            );
          } else {
            return (
              <p
                className={column.itemClass || ''}
                data-cy={generateDataCy('p', `TableCell-${String(column.fieldName)}`)}
              >
                {formattedElement}
              </p>
            );
          }
        }
        case TableCellTypes.AGGREGATE_COLUMNS: {
          let element = '';
          column.columnsToAggregate?.forEach((column) => {
            const valueToAdd = String(data[column as keyof T]);
            if (valueToAdd !== 'undefined') {
              element += ` ${String(data[column as keyof T])}`;
            }
          });
          const formattedElement = column.textFormatter ? column.textFormatter(element) : element;
          if (column.linkable) {
            const redirectTo = `${column.baseUrl}/${String(data[column.actionElementIdentifier as keyof T])}`;
            return (
              <p
                className={classnames(classes.link, column.itemClass || '')}
                data-cy={generateDataCy('p', `TableCell-${String(column.fieldName)}`)}
                onClick={() => navigate(redirectTo)}
              >
                {formattedElement}
              </p>
            );
          } else {
            return (
              <p
                className={column.itemClass || ''}
                data-cy={generateDataCy('p', `TableCell-${String(column.fieldName)}`)}
              >
                {formattedElement}
              </p>
            );
          }
        }
        case TableCellTypes.ELEMENT:
          if (column.hideElementIfNull && data[column.fieldName as keyof T] === null) {
            return null;
          } else {
            const CustomElement = column.element;
            return CustomElement ? <CustomElement column={column} rowData={data} /> : null;
          }
        case TableCellTypes.REACT_TABLE_DATA_ELEMENT:
          if (column.hideElementIfNull && data[column.fieldName as keyof T] === null) {
            return null;
          } else {
            const CustomElement = column.element;
            return CustomElement ? <CustomElement column={column} rowData={data} /> : null;
          }
        case TableCellTypes.CUSTOM_ELEMENT:
          if (column.getCustomElement) {
            return column.getCustomElement(
              data[column.actionElementIdentifier as keyof T],
              column.actionElementSecondIdentifier && data[column.actionElementSecondIdentifier as keyof T],
              column.actionElementThirdIdentifier && data[column.actionElementThirdIdentifier as keyof T],
              column.actionElementFourthIdentifier && data[column.actionElementFourthIdentifier as keyof T],
            );
          }
          return null;
        case TableCellTypes.ACTION: {
          let buttonText = '';
          if (column?.actionButtonTextFromMapping) {
            buttonText = String(data[column.actionButtonTextFromMapping as keyof T]);
          } else if (column.actionButtonText) {
            buttonText = column?.actionButtonText;
          }
          return (
            <NewButton
              className={column.itemClass || ''}
              onClick={() => {
                if (column.referenceAction && column.actionElementIdentifier) {
                  return column.referenceAction(String(data[column.actionElementIdentifier as keyof T]));
                }
              }}
              size="small"
              text={buttonText}
              variant="Secondary"
            />
          );
        }
        case TableCellTypes.MORE_ACTIONS: {
          return (
            <MoreOptions
              actions={column.moreActions}
              itemIdentifier={String(data[column.actionElementIdentifier as keyof T])}
            />
          );
        }
        case TableCellTypes.ICONS_ACTIONS: {
          return (
            <IconsOptions
              data={data}
              iconsActions={column.iconsActions}
              itemIdentifier={String(data[column.actionElementIdentifier as keyof T])}
            />
          );
        }
        case TableCellTypes.CHECKBOX: {
          return (
            <Checkbox
              isLoading={'checkBoxLoading' in column && column.checkBoxLoading}
              onChange={(event) => {
                if (column.checkBoxAction && column.actionElementIdentifier) {
                  return column.checkBoxAction(
                    Boolean(event.target.checked),
                    String(data[column.actionElementIdentifier as keyof T]),
                    String(data[column.actionElementSecondIdentifier as keyof T]),
                  );
                }
              }}
              showRadioButton={column.showRadioButton}
              value={
                column.checkFromList
                  ? column.checkFromList.includes(String(data[column.actionElementIdentifier as keyof T]))
                  : false
              }
            />
          );
        }
        case TableCellTypes.EDITABLE_INPUT: {
          return <EditableInput column={column} data={data} type={EditableCellTypes.INPUT} />;
        }
        case TableCellTypes.EDITABLE_DATE_PICKER: {
          return <EditableInput column={column} data={data} type={EditableCellTypes.DATE_PICKER} />;
        }
        default:
          return null;
      }
    },
    [column],
  );

  const cell = (
    <MUTCell
      key={String(column.fieldName)}
      align={column.align}
      className={classnames(classes.tableCell, bodyCellClass, { hidden: column.hide })}
      style={tablePadding ? { padding: tablePadding } : tableDefaultPadding}
    >
      {getCustomCell(data)}
    </MUTCell>
  );

  let tooltipText = '';
  if (column.tooltipFromProp) {
    tooltipText = String(data[column.tooltipFromProp as keyof T]);
  } else if (column.tooltipText) {
    tooltipText = column.tooltipText;
  }

  if (column.tooltipFromProp || column.tooltipText) {
    return (
      <Tooltip
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': tooltipSx,
          },
        }}
        disableFocusListener
        disableTouchListener
        placement="top-start"
        title={tooltipText}
      >
        {cell}
      </Tooltip>
    );
  }
  return cell;
};
