import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { useState } from 'react';

import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import DynamicNavigateButton from '#/components/features/dynamicRibbon/dynamicComponents/DynamicNavigateButton';
import DynamicTaskActionList from '#/components/features/dynamicRibbon/dynamicComponents/DynamicTaskActionList';

import { useSubmitCampaignDynamicRibbon } from '#/hooks/forms/campaigns/instances/useSubmitCampaignDynamicRibbon';

import { IDynamicRibbonModalData } from '#/interfaces/campaigns/campaignsInstances';
import {
  IDynamicButtonComponent,
  IDynamicNavigateButton,
  IDynamicSingleTier,
  IDynamicStringComponent,
  IDynamicTacticComponentItem,
  IDynamicTaskActions,
  IIPostDynamicRibbonProps,
  ITiersContent,
} from '#/interfaces/dynamicRibbon/dynamicRibbon';

import SendIcon from '../../../assets/SendGreyIcon.svg';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import CampaignDynamicRibbonModal from '../campaigns/instances/overview/CampaignDynamicRibbonModal';
import { styles, useDynamicRibbonStyles } from './DynamicRibbon.styles';
import DynamicButton from './dynamicComponents/DynamicButton';
import DynamicFileUploadReference from './dynamicComponents/DynamicFileUploadReference';
import DynamicSingleText from './dynamicComponents/DynamicSingleText';
import DynamicSingleTier from './dynamicComponents/DynamicSingleTier';
import DynamicTacticsInventory from './dynamicComponents/DynamicTacticsInventory';
import DynamicTextInput from './dynamicComponents/DynamicTextInput';
import DynamicTextLabel from './dynamicComponents/DynamicTextLabel';
import DynamicTiers from './dynamicComponents/DynamicTiers';

const DynamicRibbon = ({ response, submitCallback = () => null, isLoading = false }: IIPostDynamicRibbonProps) => {
  const { watch, register, setValue, onSubmit, errors, trigger, reset, unregister } = useSubmitCampaignDynamicRibbon();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({} as IDynamicRibbonModalData);
  const classes = useDynamicRibbonStyles();

  const submitCallBackAndResetForm = () => {
    reset();
    submitCallback();
  };

  const isEmpty = response?.componentRows.length === 0;
  if (isEmpty) return null;

  return (
    <Card>
      {isLoading ? (
        <Loader />
      ) : !response ? null : (
        <>
          {Object.values(modalData).length !== 0 && open && (
            <CampaignDynamicRibbonModal
              data={modalData}
              errors={errors}
              onSubmit={onSubmit}
              open={open}
              register={register}
              setOpen={setOpen}
              unregister={unregister}
            />
          )}
          <Accordion className={classes.ribbonContainer} defaultExpanded={true}>
            <AccordionSummary
              className={classes.ribbonTitleContainer}
              expandIcon={<ExpandMoreIcon />}
              sx={styles.padding0}
            >
              <img alt="expand icon" src={SendIcon} />
              <TypographyWithFontFamily variant="H4_Base">{response?.title}</TypographyWithFontFamily>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails} sx={styles.padding0}>
              <Box>
                {response?.componentRows.map((row, index: number) => (
                  <Box key={index}>
                    {row.components.map((component) => (
                      <Box key={component.actionInputId}>
                        {component.type === 'TIERS' && (
                          <DynamicTiers
                            setValue={setValue}
                            tiers={component.content as ITiersContent[]}
                            watch={watch}
                          />
                        )}
                        {component.type === 'TEXT_INPUT' && (
                          <DynamicTextInput component={component as IDynamicStringComponent} register={register} />
                        )}
                        {component.type === 'TEXT_INPUT_READONLY' && (
                          <DynamicTextInput
                            component={component as IDynamicStringComponent}
                            readonly={true}
                            register={register}
                          />
                        )}
                        {component.type === 'SINGLE_TEXT' && (
                          <DynamicSingleText component={component as IDynamicStringComponent} />
                        )}
                        {component.type === 'TASK_ACTION_LIST' && (
                          <DynamicTaskActionList
                            component={component as IDynamicTaskActions}
                            errors={errors}
                            setValue={setValue}
                            trigger={trigger}
                            watch={watch}
                          />
                        )}
                        {component.type === 'TIER' && <DynamicSingleTier component={component as IDynamicSingleTier} />}
                        {component.type === 'TEXT_LABEL' && (
                          <DynamicTextLabel component={component as IDynamicStringComponent} />
                        )}
                        {component.type === 'FILE_UPLOAD_REFERENCE' && (
                          <DynamicFileUploadReference component={component as IDynamicStringComponent} />
                        )}
                        {component.type === 'TIER_TACTICS_INVENTORY' && (
                          <DynamicTacticsInventory component={component.content as IDynamicTacticComponentItem[]} />
                        )}
                      </Box>
                    ))}
                    <Box className={classes.buttonsContainer}>
                      <Box>
                        {row.components.map((component) => (
                          <Box key={component.actionInputId}>
                            {component.type === 'BUTTON' && component.align === 'LEFT' && (
                              <DynamicButton
                                component={component as IDynamicButtonComponent}
                                errors={errors}
                                setModalData={setModalData}
                                setOpen={setOpen}
                                submitCallback={submitCallBackAndResetForm}
                                watch={watch}
                              />
                            )}
                          </Box>
                        ))}
                      </Box>
                      <Box className={classes.rightButtonsContainer}>
                        {row.components.map((component, index: number) => (
                          <Box key={component.actionInputId}>
                            {component.type === 'BUTTON' && component.align === 'RIGHT' && (
                              <DynamicButton
                                component={component as IDynamicButtonComponent}
                                errors={errors}
                                isLast={index === row.components.length - 1}
                                setModalData={setModalData}
                                setOpen={setOpen}
                                submitCallback={submitCallBackAndResetForm}
                                watch={watch}
                              />
                            )}
                            {component.type === 'NAVIGATE_BUTTON' && (
                              <DynamicNavigateButton
                                component={component as IDynamicNavigateButton}
                                errors={errors}
                                isLast={index === row.components.length - 1}
                                setModalData={setModalData}
                                setOpen={setOpen}
                                submitCallback={submitCallBackAndResetForm}
                                watch={watch}
                              />
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Card>
  );
};

export default DynamicRibbon;
