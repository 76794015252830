import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useGetRibbonBrandCampaignInstanceMutation } from '#/store/api/ribbon/ribbon';

import DynamicRibbon from '../../../dynamicRibbon/DynamicRibbon';

const CampaignInstanceDynamicRibbon = () => {
  const { instanceId } = useParams();
  const [trigger, { data: dynamicRibbonData, isLoading }] = useGetRibbonBrandCampaignInstanceMutation();

  useEffect(() => {
    trigger({ instanceId });
  }, []);

  return (
    <DynamicRibbon isLoading={isLoading} response={dynamicRibbonData} submitCallback={() => trigger({ instanceId })} />
  );
};

export default CampaignInstanceDynamicRibbon;
