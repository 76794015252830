import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorBoundary from '#/components/ErrorBoundary/ErrorBoundary';
import { oktaConfig } from '#/constants/authentication/oktaConfig';
import AppRoutes from '#/constants/routing/AppRoutes';

export const SecurityRoutes = (): JSX.Element => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback((oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  }, []);

  useEffect(() => {
    return () => {
      oktaConfig.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={oktaConfig} restoreOriginalUri={restoreOriginalUri}>
      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>
    </Security>
  );
};
