import { Box } from '@mui/material';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import NewButton from '#/components/common/button/NewButton';
import TextField from '#/components/common/inputs/TextField/TextField';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useLoginStyles } from '#/components/features/login/Login.style';

import { useLoginForm } from '#/hooks/forms/login/useLoginForm';
import { useDataCy } from '#/hooks/useDataCy';

const LoginForm = () => {
  const classes = useLoginStyles();
  const generateDataCy = useDataCy();
  const {
    onSubmit,
    errors,
    errorFlag,
    getValues,
    setValue,
    isFederatedUser,
    email,
    setEmail,
    checkForFederation,
    backHandler,
  } = useLoginForm();

  if (isFederatedUser) {
    return (
      <>
        <div className={classes.inputContainer}>
          <TextField
            data-cy={generateDataCy('input', 'username')}
            error={errors.username?.message}
            label="Username"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter the email address associated with your account"
            value={email}
          />
        </div>
        <NewButton className={classes.fullWidth} disabled={!email.length} onClick={checkForFederation} text="Sign In" />
      </>
    );
  }
  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <NewButton onClick={backHandler} text="Back" variant="Ghost" />
      <div className={classes.inputContainer}>
        <TextField
          data-cy={generateDataCy('input', 'username')}
          error={errors.username?.message}
          label="Username"
          onChange={(e) => setValue('username', e.target.value)}
          placeholder="Enter the email address associated with your account"
          value={getValues('username')}
        />
      </div>
      <Box className={classes.inputContainer}>
        <TextField
          data-cy={generateDataCy('input', 'password')}
          error={errors.password?.message}
          label="Password"
          onChange={(e) => setValue('password', e.target.value)}
          type="password"
          value={getValues('password')}
        />
        {isEmpty(errors) && (
          <TypographyWithFontFamily className={classnames(classes.errorMessage, { [classes.hidden]: !errorFlag })}>
            Authentication failed
          </TypographyWithFontFamily>
        )}
      </Box>
      <NewButton className={classes.fullWidth} text="Sign In" type="submit" />
    </form>
  );
};
export default LoginForm;
