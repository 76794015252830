import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import getCommunicationsSelectBrandsTabletTableColumns from '#/components/features/communications/tables/CommunicationsSelectBrandsTable.tableColumns';

import { useAppSelector } from '#/hooks';

import {
  useGetCommunicationsCompaniesQuery,
  useGetPromoteCompaniesFilterQuery,
} from '#/store/api/communications/communications';
import { communicationsPromote } from '#/store/slices';

import Loader from '../../../common/loader/loader';
import { Table } from '../../../common/table/tableComponent/Table';
import { useBrandStyles } from '../../partners/brands/brands.styles';

// TODO: add back users on second step of recipients
const CommunicationsSelectBrandsTable = ({ brandsFilters }) => {
  const styles = useBrandStyles();
  const { selectedCampaigns } = useAppSelector((state) => state.communications);
  const selectBrandIds = useAppSelector((state) => communicationsPromote.getSelectedBrandsIds(state.communications));

  const { data: filterData, isError: filterDataIsError } = useGetPromoteCompaniesFilterQuery({
    singleCampaign: selectedCampaigns.length === 1,
  });

  const { actions, state } = useSearchFilters(undefined, false, filterData?.filters);

  const { data, isLoading, isError, isFetching } = useGetCommunicationsCompaniesQuery({
    campaignIds: selectedCampaigns,
    filters: brandsFilters,
    numberOfRows: state.numberOfElementsPerPage,
    pageNumber: state.pageNumber,
    searchQuery: state.searchQuery,
  });

  const tableColumns = getCommunicationsSelectBrandsTabletTableColumns(
    data?.companies || [],
    selectBrandIds,
    selectedCampaigns,
    filterData?.filters || [],
  );

  if (isLoading) return <Loader />;

  return (
    <SearchFilters
      columnNames={filterData?.filters}
      currentIndex={data?.currentIndex}
      filterDataIsError={filterDataIsError}
      hideViewMode
      isError={isError}
      isFetching={isFetching}
      isNoResults={!data || data?.companies.length === 0}
      totalPages={data?.totalPages}
      totalResults={data?.totalResults}
      {...state}
      {...actions}
    >
      <Table
        columns={tableColumns}
        containerClass={styles.availableBrandsTable}
        data={data?.companies || []}
        sortProps={{
          sortParams: state.sortParams,
          setSortParams: actions.setSortParam,
        }}
      />
    </SearchFilters>
  );
};
export default CommunicationsSelectBrandsTable;
