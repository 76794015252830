import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC, ReactNode } from 'react';

import AlertError from '#/components/common/Alert/AlertError/AlertError';

import { useAppSelector } from '#/hooks';

import { THEME } from '../../../constants/theme/constants';
import EditComboButton from '../../../utils/EditComboButton';
import { TypographyWithFontFamily } from '../../common/typography/TypographyWithFontFamily';
import { style, useTopSectionStyles } from './TopSection.style';

interface ITopSectionProps {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  sectionName: string;
  requiredPermission?: string;
  reset: () => void;
  refetch: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  dataLoading: boolean;
  customHeader?: ReactNode;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const TopSection: FC<ITopSectionProps> = ({
  setEditMode,
  editMode,
  requiredPermission,
  sectionName,
  reset,
  refetch,
  onSubmit,
  isLoading,
  dataLoading,
  customHeader,
  error,
}: ITopSectionProps) => {
  const { permissions } = useAppSelector((state) => state.user);
  const classes = useTopSectionStyles();

  const hasWritePermissions = requiredPermission ? permissions.includes(requiredPermission) : true;

  return (
    <div className={classes.container}>
      <div className={classes.rowContainer}>
        {customHeader ? (
          !dataLoading && customHeader
        ) : (
          <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Dark} sx={style.sectionName} variant="H4_Base">
            {sectionName}
          </TypographyWithFontFamily>
        )}
        {hasWritePermissions && (
          <div className={classes.buttonsSection}>
            <EditComboButton
              dataLoading={dataLoading}
              edit={editMode}
              isLoading={isLoading}
              onSubmit={onSubmit}
              refetch={refetch}
              reset={reset}
              setEdit={setEditMode}
            />
          </div>
        )}
      </div>
      {editMode && <AlertError error={error} />}
    </div>
  );
};
