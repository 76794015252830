import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';

import { BASE_PATH, HOST_URL } from '#/constants/general';

import { useWebsocket } from '#/hooks/useWebsocket';

import { user } from '#/store/slices';

import { storage } from '#/utils/storage';

export const useLogout = () => {
  const okta = useOktaAuth();
  const { disconnectWebsocket } = useWebsocket();
  const dispatch = useDispatch();

  const logout = async () => {
    try {
      const idToken = okta.authState?.idToken;
      if (idToken) {
        await okta.oktaAuth.signOut({
          revokeAccessToken: false,
          postLogoutRedirectUri: `${HOST_URL}${BASE_PATH}`,
        });

        disconnectWebsocket();
        dispatch(user.actions.logout());
        storage.clear();
        window.location.reload();
      }
    } catch (e) {
      throw new Error('logout process failed');
    }
  };

  return logout;
};
