import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import BookMarkIcon from '../../../../assets/Bookmark.svg';
import { IQuickLinks } from '../../../../interfaces/dashboard/quickLinks';
import DisplayPageName from '../../../common/displayPageName/DisplayPageName';
import { marginBottom, useQuickLinksStyles } from '../Dashboard.styles';

export const QuickLinks = ({ links }: { links: IQuickLinks[] }) => {
  const classes = useQuickLinksStyles();

  return (
    <Box className={classes.wrapperDiv}>
      <DisplayPageName
        customStyles={marginBottom}
        customVariant="H4_Base"
        imgSrc={BookMarkIcon}
        pageName="Quick Links"
      />
      <Box className={classes.linksContainer}>
        {links.map((link) => (
          <Link key={link.url} className={classes.link} to={link.url}>
            <img alt="link icon" className={classes.linkIcon} src={link.icon} />
            <p className={classes.linkText}>{link.name}</p>
          </Link>
        ))}
      </Box>
    </Box>
  );
};
