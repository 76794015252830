import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';

import { setOktaTokenToAxios } from '#/api/axiosConfig';

import { UnexistingRoute } from '#/components/common/error/UnexistingRoute';
import Login from '#/components/features/login/Login';

import { useAppSelector } from '#/hooks';
import { useWebsocket } from '#/hooks/useWebsocket';

import { useLazyGetCurrentUserProfileQuery } from '#/store/api/users/users';
import { user as userSlice } from '#/store/slices';

import { USER_TYPES, USER_TYPES_HIDDEN } from '#/utils/UserTypeWrapper';
import { storage } from '#/utils/storage';

import PageLayout from '../../components/common/layout/pageLayout';
import FullPageLoader from '../../components/common/loader/FullPageLoader';
import CreateBrandUserForm from '../../components/features/settings/brandUserForm/BrandUserForm';
import { oktaConfig } from '../authentication/oktaConfig';
import { BASE_PATH } from '../general';
import { PUBLIC_ROUTE_PATHS, ROUTE_PATHS } from './routePaths';
import { IMainRoute, IRoute, routesList } from './routesList';

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const websocket = useWebsocket();
  const { pathname, search } = useLocation();
  const { authState } = useOktaAuth();

  const token = oktaConfig.getAccessToken();

  const user = useAppSelector((state) => state.user);
  const [trigger, { data, error }] = useLazyGetCurrentUserProfileQuery();

  const [status, setStatus] = useState<'pending' | 'anonymous' | 'authorized'>('pending');

  const path = useMemo(() => pathname.replace(/^\//, '') as unknown as PUBLIC_ROUTE_PATHS, [pathname]);

  const isPublicPath = useMemo(() => Object.values(PUBLIC_ROUTE_PATHS).includes(path), [pathname]);

  useEffect(() => {
    if (!authState && !isPublicPath) {
      storage.setRedirectUrl(pathname, search);
    } else if (authState?.isAuthenticated === false) {
      localStorage.removeItem('okta-token-storage');
      setStatus('anonymous');

      !isPublicPath && navigate(ROUTE_PATHS.LOGIN, { replace: true });
    } else if (
      authState?.isAuthenticated &&
      authState.accessToken?.accessToken &&
      user.authToken !== authState.accessToken.accessToken
    ) {
      dispatch(userSlice.actions.setAuthToken(authState.accessToken.accessToken));
      setStatus('pending');

      navigate(
        {
          pathname,
          search,
        },
        { replace: true },
      );
    }
  }, [authState?.isAuthenticated, isPublicPath, token]);

  // Once the user is authenticated, we can initialize the app
  useEffect(() => {
    if (user.authToken) {
      trigger();

      websocket.connectWebsocket(user.authToken);

      //@TODO Remove axios
      setOktaTokenToAxios(user.authToken);
    }
  }, [user.authToken]);

  // We are ready to bootstrap the app once we have the user data
  useEffect(() => {
    if (data) {
      dispatch(userSlice.actions.setUser(data));
      dispatch(userSlice.actions.setPermissions(data.permissions));

      setStatus('authorized');

      const redirectUrl = storage.getRedirectUrl();
      navigate(redirectUrl, { replace: true });
      storage.deleteRedirectUrl();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(userSlice.actions.logout());
      setStatus('anonymous');
    }
  }, [error]);

  const userPermissions = user.permissions;
  const userType = user.user?.userType;

  const isRouteAllowed = (route: IMainRoute | IRoute) => {
    if (userType === USER_TYPES_HIDDEN.SUPER_ADMIN) return true;
    if (route.permission && userPermissions && !userPermissions.includes(route.permission)) {
      return false;
    }
    if (route.allowedUserTypes && userType && !route.allowedUserTypes.includes(userType as USER_TYPES)) {
      return false;
    }
    return true;
  };

  const authorizedRoutes = useMemo(() => {
    const filteredRoutes = routesList.filter(isRouteAllowed).map((mainRoute) => ({
      ...mainRoute,
      children: mainRoute.children?.filter(isRouteAllowed),
    }));

    return filteredRoutes.map((mainRoute) => {
      return (
        <Route key={mainRoute.path} element={<mainRoute.component />} path={mainRoute.path}>
          {(mainRoute.children ?? []).map((childrenRoute) => {
            return <Route key={childrenRoute.path} element={<childrenRoute.component />} path={childrenRoute.path} />;
          })}
        </Route>
      );
    });
  }, [user.permissions]);

  return (
    <Routes>
      <Route element={status === 'anonymous' ? <Login /> : <FullPageLoader />} path={ROUTE_PATHS.LOGIN} />
      <Route element={<CreateBrandUserForm />} path={ROUTE_PATHS.SIGNUP} />
      <Route element={<LoginCallback />} path={ROUTE_PATHS.CALLBACK} />
      {status === 'pending' && <Route element={<FullPageLoader />} path="*" />}
      {status === 'authorized' && (
        <>
          <Route path={BASE_PATH}>
            <Route element={<PageLayout />}>{authorizedRoutes}</Route>
            <Route element={<UnexistingRoute />} path="*" />
          </Route>
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
