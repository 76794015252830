import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { pipe } from 'fp-ts/lib/function';
import { PathReporter } from 'io-ts/PathReporter';

export enum CAMPAIGN_STAGE_HEADER {
  CAMPAIGN_CREATION = 'CAMPAIGN_CREATION',
}

export const productListElementsPerPageOptions = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

export const communicationsBreadcrumbList = [
  'Select Brands',
  'Select Recipients',
  'Marketing Materials',
  'Review & Send',
];

export enum COMMUNICATIONS_TABS {
  COMMUNICATIONS_INVITE_BRANDS,
  COMMUNICATIONS_INVITE_RECIPIENTS,
  COMMUNICATIONS_MARKETING,
  COMMUNICATIONS_REVIEW,
}

export const invitationStatuses = [
  { value: '', label: 'All' },
  { value: 'ACCEPTED', label: 'Accepted' },
  { value: 'REQUESTED_REVISIONS', label: 'Requested Revisions' },
  { value: 'DECLINED', label: 'Declined' },
  { value: 'PENDING_RESPONSE', label: 'Pending Response' },
  { value: 'NOT_INVITED', label: 'Not invited' },
];

export const validateEnv = <A, O>(env: t.Type<A, O>, name: string, defaultValue?: A): A =>
  pipe(
    import.meta.env[name],
    env.decode,
    E.getOrElseW((errors) => {
      if (!t.undefined.is(defaultValue)) {
        return defaultValue;
      }
      throw new Error(`Missing environment variable: ${name}, ${PathReporter.report(E.left(errors)).join(', ')}`);
    }),
  );

// .env file
export const isDevelopment = import.meta.env.DEV;
export const GATEWAY_API_URL = validateEnv(t.string, 'VITE_APP_GATEWAY_API_URL');
export const FULL_CALENDAR_LICENSE = validateEnv(t.string, 'VITE_APP_FULL_CALENDAR_LICENSE');
export const HOST_URL = validateEnv(t.string, 'VITE_APP_HOST_URL');
export const BASE_PATH = validateEnv(t.string, 'VITE_APP_BASE_PATH', '/');
export const WEBSOCKET_URL = validateEnv(t.string, 'VITE_APP_WEBSOCKET_URL', '/');

export const OKTA_ISSUER = validateEnv(
  t.string,
  'VITE_APP_OKTA_ISSUER',
  'https://dev-99915776.okta.com/oauth2/aus9v9diwoxqxHGHQ5d7',
);
export const OKTA_CLIENT_ID = validateEnv(t.string, 'VITE_APP_OKTA_CLIENT_ID', '0oa9uolyarCq8UGcH5d7');
export const OKTA_REDIRECT_URI = validateEnv(t.string, 'VITE_APP_OKTA_REDIRECT_URI', 'nothing');
