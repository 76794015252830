import { Box, TextField } from '@mui/material';
import { useState } from 'react';

import { useDataCy } from '#/hooks/useDataCy';

import { USER_PERMISSIONS } from '../../constants/permissions/userPermissions';
import { THEME } from '../../constants/theme/constants';
import { useOverviewEditMarketing } from '../../hooks/forms/campaigns/use-overview-edit-marketing';
import ImageDownloadUpload from '../common/downloadUpload/ImageDownloadUpload';
import MultipleDownloadUpload from '../common/downloadUpload/MultipleDownloadUpload';
import SingleDownloadUpload from '../common/downloadUpload/SingleDownloadUpload';
import Loader from '../common/loader/loader';
import { TypographyWithFontFamily } from '../common/typography/TypographyWithFontFamily';
import {
  textAreaEditMode,
  textAreaReadOnlyMode,
  upperCaseSx,
  useCampaignOverviewMarketingStyles,
} from './CampaignOverviewMarketing.style';
import { TopSection } from './topSection/TopSection';

const CampaignOverviewMarketing = () => {
  const generateDataCy = useDataCy();

  const [editMode, setEditMode] = useState(false);
  const classes = useCampaignOverviewMarketingStyles();

  const { onSubmit, errors, register, isLoading, dataLoading, reset, refetch, watch, setValue, error } =
    useOverviewEditMarketing(() => setEditMode(false));

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.marketingContainer} data-cy={generateDataCy('box', 'CampaignOverviewMarketing')}>
          <TopSection
            dataLoading={dataLoading}
            editMode={editMode}
            error={error}
            isLoading={isLoading}
            onSubmit={onSubmit}
            refetch={refetch}
            requiredPermission={USER_PERMISSIONS.CAMPAIGN_OVERVIEW_INTERNAL_WRITE}
            reset={reset}
            sectionName="Marketing"
            setEditMode={setEditMode}
          />
          {dataLoading ? (
            <Loader />
          ) : (
            <Box className={classes.marketingSectionsContainer}>
              <Box className={classes.marketingSection}>
                <Box>
                  <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                    Campaign Promotional Files
                  </TypographyWithFontFamily>
                  <SingleDownloadUpload
                    editMode={editMode}
                    fileId={watch('promotionalFileId')}
                    registerName="promotionalFileId"
                    setValue={setValue}
                    uploadLink={'/campaign/promotional-file'}
                  />
                </Box>

                <Box>
                  <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                    Additional files
                  </TypographyWithFontFamily>
                  <MultipleDownloadUpload
                    editMode={editMode}
                    extensions={['.pdf', '.png', '.jpeg', '.jpg']}
                    filesIds={watch('additionalFilesId')}
                    registerName="additionalFilesId"
                    setValue={setValue}
                    uploadLink={`/campaign/marketing-additional-files`}
                  />
                </Box>
                <Box>
                  <TypographyWithFontFamily color={THEME.PALETTES.TEXT.Gray} sx={upperCaseSx} variant="Label_Base">
                    Campaign Summary (For Brands)
                  </TypographyWithFontFamily>
                  <TextField
                    date-cy="Campaign Summary For Brands"
                    helperText={errors.summary?.message?.toString()}
                    {...register('summary')}
                    disabled={!editMode}
                    fullWidth
                    multiline
                    rows={4}
                    sx={editMode ? textAreaEditMode : textAreaReadOnlyMode}
                  />
                </Box>
              </Box>
              <Box className={classes.marketingSection}>
                <TypographyWithFontFamily
                  color={THEME.PALETTES.TEXT.Gray}
                  sx={{ textTransform: 'uppercase' }}
                  variant="Label_Base"
                >
                  Campaign Thumbnail
                </TypographyWithFontFamily>
                <ImageDownloadUpload
                  editMode={editMode}
                  fileId={watch('thumbnailId')}
                  registerName="thumbnailId"
                  setValue={setValue}
                  uploadLink={'/campaign/thumbnail'}
                />

                {!editMode && !watch('thumbnailId') && (
                  <TypographyWithFontFamily variant="Small_Bold">No thumbnail yet</TypographyWithFontFamily>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
};
export default CampaignOverviewMarketing;
